import React, { FC, useEffect, useMemo, useState } from 'react';
import { useFlexSearch } from 'react-use-flexsearch';
import { graphql, useStaticQuery } from 'gatsby';
import { Typography } from '@agria/theme/src/components/typography/Typography';
import {
  SearchResultsInterface,
  SearchResultsOutputInterface,
} from './Search.interface';
import { SearchResultsList } from './SearchResultsList';

const ResultsList: FC<SearchResultsOutputInterface> = ({ results }) => (
  <>
    {results.pages.length > 0 && <SearchResultsList results={results.pages} />}

    {results.blogs.length > 0 && (
      <SearchResultsList
        results={results.blogs}
        title="Related Blog Articles"
      />
    )}

    {results.guides.length > 0 && (
      <SearchResultsList
        results={results.guides}
        title="Related Guides and Advice"
      />
    )}

    {results.news.length > 0 && (
      <SearchResultsList results={results.news} title="Related News Articles" />
    )}
  </>
);

const SearchResults: FC<SearchResultsInterface> = ({
  currentPage = 1,
  onTotalCountUpdate,
  pageSize = 10,
  searchQuery,
  usePagination = false,
}) => {
  const [numberOfResults, setNumberOfResults] = useState<number>(0);

  const paginateResults = (results: any[]) => {
    if (!usePagination || !currentPage || !pageSize) return results;

    const start = (currentPage - 1) * pageSize;
    const end = start + pageSize;
    return results.slice(start, end);
  };

  const data = useStaticQuery(graphql`
    query SearchResultsIndexQuery {
      localSearchAllBlogArticles {
        index
        store
      }
      localSearchAllGuidesAndAdviceArticles {
        index
        store
      }
      localSearchAllNewsArticles {
        index
        store
      }
      localSearchAllPages {
        index
        store
      }
    }
  `);

  const GetResults = (index: any, store: any) =>
    useFlexSearch(searchQuery, index, store, {
      profile: 'speed',
    });

  const allPagesResults = GetResults(
    data.localSearchAllPages.index,
    data.localSearchAllPages.store
  );

  const allBlogArticlesResults = GetResults(
    data.localSearchAllBlogArticles.index,
    data.localSearchAllBlogArticles.store
  );

  const allGuidesAndAdviceArticlesResults = GetResults(
    data.localSearchAllGuidesAndAdviceArticles.index,
    data.localSearchAllGuidesAndAdviceArticles.store
  );

  const allNewsArticlesResults = GetResults(
    data.localSearchAllNewsArticles.index,
    data.localSearchAllNewsArticles.store
  );

  const allResults = useMemo(
    () => [
      ...allPagesResults.map((result) => ({
        ...result,
        category: 'pages',
      })),
      ...allBlogArticlesResults.map((result) => ({
        ...result,
        category: 'blogs',
      })),
      ...allGuidesAndAdviceArticlesResults.map((result) => ({
        ...result,
        category: 'guides',
      })),
      ...allNewsArticlesResults.map((result) => ({
        ...result,
        category: 'news',
      })),
    ],
    [
      allPagesResults,
      allBlogArticlesResults,
      allGuidesAndAdviceArticlesResults,
      allNewsArticlesResults,
    ]
  );

  const paginatedResults = useMemo(
    () => paginateResults(allResults),
    [allResults, currentPage, pageSize]
  );

  const results = useMemo(() => {
    const groupedResults = usePagination ? paginatedResults : allResults;

    return {
      pages: groupedResults.filter((result) => result.category === 'pages'),
      blogs: groupedResults.filter((result) => result.category === 'blogs'),
      guides: groupedResults.filter((result) => result.category === 'guides'),
      news: groupedResults.filter((result) => result.category === 'news'),
    };
  }, [allResults, paginatedResults, usePagination]);

  useEffect(() => {
    const totalResults = Number(allResults.length);
    setNumberOfResults(totalResults);

    if (onTotalCountUpdate) {
      onTotalCountUpdate(totalResults);
    }
  }, [allResults, onTotalCountUpdate]);

  return numberOfResults >= 1 && searchQuery.length >= 3 ? (
    <ResultsList results={results} />
  ) : (
    <Typography component="h4" variant="bodyMedium">
      No results found for
      {searchQuery.length >= 3 ? `' ${searchQuery}'` : ' your search'}
    </Typography>
  );
};

export { SearchResults };
