import React, { forwardRef } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Close } from '@agria/theme/src/components/icon/icons/Close';
import { Search as SearchIcon } from '@agria/theme/src/components/icon/icons/Search';

const StyledForm = styled.form`
  ${({ theme: { colors, space } }) => css`
    align-items: center;
    background-color: ${colors.utilityWhite};
    border-radius: 3px;
    border: 2px solid ${colors.brandOne};
    display: grid;
    gap: ${space.small};
    grid-template-columns: 1fr auto auto;
    padding: ${space.xxSmall} ${space.small};
    width: 100%;

    input {
      min-width: 100px;
      border: none;
      color: ${colors.brandOne};
      flex: 1;
      font-size: 1rem;
      font-weight: 700;
      line-height: 22px;

      &:focus {
        outline: none;
      }

      &::placeholder {
        color: #355a7c;
        font-weight: 400;
      }
    }

    input[type='search']::-webkit-search-decoration,
    input[type='search']::-webkit-search-cancel-button,
    input[type='search']::-webkit-search-results-button,
    input[type='search']::-webkit-search-results-decoration {
      -webkit-appearance: none;
    }

    button {
      background-color: transparent;
      border: none;
      color: ${colors.brandOne};
      cursor: pointer;
      display: flex;
      justify-content: center;
      padding: 0;

      &[type='submit'] {
        grid-column: 3;
      }

      &[type='button'] {
        grid-column: 2;

        svg {
          height: 24px;
          width: 24px;
        }
      }
    }
  `};
`;

const SearchForm = forwardRef<
  HTMLInputElement,
  {
    autoFocus?: boolean;
    inputValue?: string;
    onChange?: any;
    onClick?: any;
    onSubmit?: any;
  }
>(({ autoFocus, onClick, onChange, onSubmit, inputValue = '' }, ref) => (
  <StyledForm onSubmit={onSubmit}>
    <input
      autoComplete="off"
      autoFocus={autoFocus}
      id="searchInput"
      name="Search"
      onChange={onChange}
      placeholder="Search..."
      ref={ref}
      type="search"
      value={inputValue}
    />

    {inputValue && inputValue.length > 0 && (
      <button aria-label="Clear Search" onClick={onClick} type="button">
        <Close />
      </button>
    )}

    {onSubmit && (
      <button
        aria-label="Search"
        disabled={inputValue.length <= 0}
        type="submit"
      >
        <SearchIcon />
      </button>
    )}
  </StyledForm>
));
SearchForm.displayName = 'SearchForm';
const SearchFormMemo = React.memo(SearchForm);

export { SearchFormMemo };
