import React, { FC } from 'react';
import { Link } from 'gatsby';
import { Typography } from '@agria/theme/src/components/typography/Typography';
import { Divider } from '@agria/theme/src/components/divider/Divider';
import styled from '@emotion/styled';
import { SearchResultsListInterface } from './Search.interface';

const StyledResults = styled.div`
  display: grid;
  gap: ${({ theme: { space } }) => space.xSmall};

  ul {
    display: grid;
    gap: ${({ theme: { space } }) => space.small};
    list-style: none;
    margin: 0;
    padding: 0;
  }

  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const SearchResultsList: FC<SearchResultsListInterface> = ({
  results,
  title,
}) => (
  <StyledResults>
    {title && (
      <>
        <Typography component="h4" variant="bodyMediumBold">
          {title}
        </Typography>
        <Divider />
      </>
    )}

    {results &&
      results.map((result) => (
        <ul>
          <li key={result.id}>
            <Link to={result.url}>{result.label}</Link>
          </li>
        </ul>
      ))}
  </StyledResults>
);

export { SearchResultsList };
