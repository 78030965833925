import React, { useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Header as HeaderComponent } from '@agria/theme/src/components/headers/header/Header';
import {
  MyAccountContextType,
  useMyAccount,
} from '@agria/theme-my-account/src/context/MyAccountContext';
import { isBrowser } from '@agria/utils';

import { siteLinks } from '@agria/theme/src/const/siteLinks';

const Header = ({ brand, location }) => {
  // Determine if in my account
  const pathname = isBrowser ? window.location.pathname : '';
  const isMyAccount = pathname.includes('my-account');

  const [ctaText, setCtaText] = useState('');
  const [ctaLink, setCtaLink] = useState('');

  const { headerData, site, siteData } = useStaticQuery(graphql`
    query HeaderQuery {
      siteData: umbracoSiteSettingsType {
        siteId
      }
      site {
        siteMetadata {
          enableMyAccount
          contactNumber
        }
      }
      headerData: umbracoHeaderType {
        secondaryNavigation {
          block {
            components {
              ... on UMBRACO_NavigationLinkComponentType {
                __typename
                link {
                  ...Link
                }
              }
            }
          }
        }
        navigationItems {
          block {
            components {
              ... on UMBRACO_NavigationItemComponentType {
                __typename
                hideTopLevelNavigation
                hideTelephoneNumber
                ctaOverride {
                  ...Link
                }
                link {
                  ...Link
                }
                headerChildItems {
                  link {
                    ...Link
                  }
                  navigationChildItems {
                    link {
                      ...Link
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  // My account (for login/logout button)

  const { isUserAuthenticated, navItems, logout } = useMyAccount();

  // Work our correct nav to show

  let navigationItems = headerData?.navigationItems[0].block.components;
  let secondaryNavigationItems =
    headerData?.secondaryNavigation[0]?.block.components;

  // My account mobile navigation

  if (isBrowser && isMyAccount && isUserAuthenticated && navItems?.length > 0) {
    navigationItems = navItems.map((item) => ({
      hideTopLevelNavigation: false,
      hideTelephoneNumber: false,
      ctaOverride: {
        label: item.ctaOverride?.label,
        url: item.ctaOverride?.url,
      },
      telephoneNumberOverride: item.telephoneNumberOverride,
      link: {
        label: item.label,
        target: null,
        url: item.url,
      },
    }));

    secondaryNavigationItems = null;
  }

  return (
    <HeaderComponent
      navigationItems={navigationItems}
      secondaryNavigationItems={secondaryNavigationItems}
      siteId={siteData?.siteId}
      brand={brand}
      enableMyAccount={site?.siteMetadata?.enableMyAccount}
      contactNumber={site?.siteMetadata?.contactNumber}
      loggedIn={isUserAuthenticated}
      isMyAccount={isMyAccount}
      myAccountLogout={logout}
      ctaText={ctaText}
      ctaLink={ctaLink}
      showSearch
      location={location}
    />
  );
};

export default Header;
