import { FC, useEffect, useRef } from 'react';
import { PageProps } from 'gatsby';
import { useDataLayerPush } from '@agria/theme/src/hooks/useDataLayerPush';

interface ContentMapping {
  contentGroup: string | undefined;
  contentType: string | undefined;
}

const getContentMapping = (hostname: string): ContentMapping => {
  // Content Type specific mappings with their corresponding content groups
  const contentMappings: [RegExp, ContentMapping][] = [
    [
      /^basc-breeders\.(agriapet|kcinsurance)\.co\.uk/,
      {
        contentGroup: 'activation journey',
        contentType: 'breeder club - basc',
      },
    ],
    [
      /^join-basc\.(agriapet|kcinsurance)\.co\.uk/,
      {
        contentGroup: 'activation journey',
        contentType: 'breeder club - basc join',
      },
    ],
    [
      /^join-gccf\.(agriapet|kcinsurance)\.co\.uk/,
      {
        contentGroup: 'activation journey',
        contentType: 'breeder club - gccf join',
      },
    ],
    [
      /^breeders\.(agriapet|kcinsurance)\.co\.uk/,
      {
        contentGroup: 'activation journey',
        contentType: 'breeder club - main',
      },
    ],
    [
      /^join\.(agriapet|kcinsurance)\.co\.uk/,
      {
        contentGroup: 'activation journey',
        contentType: 'breeder club - main join',
      },
    ],
    [
      /^gccf-breeders\.(agriapet|kcinsurance)\.co\.uk/,
      { contentGroup: 'activation journey', contentType: 'free cover - gccf' },
    ],
    [
      /^owners\.(agriapet|kcinsurance)\.co\.uk/,
      {
        contentGroup: 'activation journey',
        contentType: 'free cover - owners',
      },
    ],
    [
      /^rehoming\.(agriapet|kcinsurance)\.co\.uk/,
      {
        contentGroup: 'activation journey',
        contentType: 'free cover - rehoming',
      },
    ],
    [
      /^vets\.(agriapet|kcinsurance)\.co\.uk/,
      { contentGroup: 'activation journey', contentType: 'free cover - vet' },
    ],
    [
      /^vet-owners\.(agriapet|kcinsurance)\.co\.uk/,
      {
        contentGroup: 'activation journey',
        contentType: 'free cover - vet owners',
      },
    ],
    [
      /^litter-data-capture\.(agriapet|kcinsurance)\.co\.uk/,
      {
        contentGroup: 'activation journey',
        contentType: 'litter data capture - main',
      },
    ],
    [
      /^makeapayment\.(agriapet|kcinsurance)\.co\.uk/,
      { contentGroup: 'missed payments', contentType: 'missed payment - main' },
    ],
    [
      /^claim\.(agriapet|kcinsurance)\.co\.uk/,
      {
        contentGroup: 'online claims',
        contentType: 'online claims - customer',
      },
    ],
    [
      /^vetclaim\.(agriapet|kcinsurance)\.co\.uk/,
      { contentGroup: 'online claims', contentType: 'online claims - vet' },
    ],
    [
      /^ctm\.(agriapet|kcinsurance)\.co\.uk/,
      {
        contentGroup: 'quote and buy journey',
        contentType: 'quote and buy - ctm',
      },
    ],
    [
      /^quote\.(agriapet|kcinsurance)\.co\.uk/,
      {
        contentGroup: 'quote and buy journey',
        contentType: 'quote and buy - main',
      },
    ],
    [
      /^service-dogs\.(agriapet|kcinsurance)\.co\.uk/,
      {
        contentGroup: 'quote and buy journey',
        contentType: 'quote and buy - service dogs',
      },
    ],
    [
      /^transfer-cover\.(agriapet|kcinsurance)\.co\.uk/,
      {
        contentGroup: 'quote and buy journey',
        contentType: 'transfer cover - main',
      },
    ],
    // Adding new Lloyds mappings
    [
      /^lloyds-quote\.agriapet\.co\.uk/,
      {
        contentGroup: 'quote and buy journey',
        contentType: 'quote and buy - main',
      },
    ],
    [
      /^lloyds\.agriapet\.co\.uk/,
      {
        contentGroup: 'brochureware pages',
        contentType: 'brochureware - main',
      },
    ],
    [
      /^lloyds-claims\.agriapet\.co\.uk/,
      {
        contentGroup: 'online claims',
        contentType: 'online claims - customer',
      },
    ],
    [
      /^lloyds-vet-claims\.agriapet\.co\.uk/,
      {
        contentGroup: 'online claims',
        contentType: 'online claims - vet',
      },
    ],
    [
      /^lloyds-makeapayment\.agriapet\.co\.uk/,
      {
        contentGroup: 'missed payments',
        contentType: 'missed payment - main',
      },
    ],
    // Special case for brochureware pages
    [
      /^www\.(agriapet\.co\.uk|agriapetinsure\.ie|kcinsurance\.co\.uk)/,
      {
        contentGroup: 'brochureware pages',
        contentType: 'brochureware - main',
      },
    ],
  ];

  // Find matching content mapping
  for (const [pattern, mapping] of contentMappings) {
    if (pattern.test(hostname)) {
      return mapping;
    }
  }

  // Return unknown values if no match is found
  return { contentGroup: undefined, contentType: undefined };
};

export interface DataLayerWrapperProps {
  children: any;
  data?: PageProps['data'];
  serverData?: PageProps['serverData'];
  location: PageProps['location'];
}

export const DataLayerWrapper: FC<DataLayerWrapperProps> = ({
  children,
  data,
  serverData,
  location,
}) => {
  const { dataLayerPush } = useDataLayerPush();
  const previousPath = useRef<string>();

  useEffect(() => {
    if (!location?.host) return;

    const { contentGroup, contentType } = getContentMapping(location.host);

    // Get the full URL including query parameters
    const currentFullUrl = location.href;

    // Get referrer from localStorage or fallback to document.referrer
    // Ensure we're storing and retrieving the full URL with query parameters
    const storedReferrer = localStorage.getItem('lastPage');

    // Use the full referrer URL, maintaining query parameters
    const pageReferrer =
      storedReferrer || previousPath.current || document.referrer;

    // Store current page for next navigation
    localStorage.setItem('lastPage', currentFullUrl);

    const pageData = {
      event: 'virtual_pageview',
      spa_page_url: currentFullUrl,
      spa_page_referrer: pageReferrer,
      ...(contentGroup && { content_group: contentGroup }),
      ...(contentType && { content_type: contentType }),
    };

    // Push constructed data to data layer
    dataLayerPush({
      ...pageData,
    });

    // Update previous path ref with full URL including query parameters
    previousPath.current = currentFullUrl;

    // Clean up only when leaving the site entirely
    const handleUnload = () => {
      localStorage.removeItem('lastPage');
    };

    window.addEventListener('beforeunload', handleUnload);
    return () => {
      window.removeEventListener('beforeunload', handleUnload);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return children;
};
