import React, { FC } from 'react';
import { createPortal } from 'react-dom';

const PortalId = ['blade-portal', 'search-portal'] as const;
interface PortalProps {
  id: (typeof PortalId)[number];
}

const Portal: FC<React.PropsWithChildren<PortalProps>> = ({ children, id }) => {
  const portalEl =
    typeof document !== `undefined` ? document.getElementById(id) : null;

  if (portalEl) {
    return createPortal(children, portalEl);
  }
  return null;
};

export type { PortalProps };
export { Portal, PortalId };
