import React, { FC, useEffect, useRef, useState } from 'react';
import { navigate } from 'gatsby';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Button } from '@agria/theme/src/components/button/Button';
import { Search as SearchIcon } from '@agria/theme/src/components/icon/icons/Search';
import { Portal } from '@agria/theme/src/components/portal/Portal';
import { Close } from '@agria/theme/src/components/icon/icons/Close';
import { useLocation } from '@gatsbyjs/reach-router';
import { SearchModalInterface } from './Search.interface';
import { SearchResults } from './SearchResults';
import { SearchFormMemo } from './SearchForm';

const StyledSearchResults = styled.div`
  ${({ theme: { colors, space } }) => css`
    background-color: ${colors.utilityWhite};
    border-radius: 3px;
    border: 2px solid ${colors.brandOne};
    overflow: hidden;
    width: 100%;

    > div {
      display: flex;
      flex-direction: column;
      height: 100%;
      gap: ${space.fluidXxSmallToLarge};
      overflow-y: auto;
      padding: ${space.medium} ${space.small};
      scroll-padding: ${space.medium};
    }
  `};
`;

const StyledCloseButton = styled.button`
  ${({ theme: { colors } }) => css`
    align-self: flex-start;
    background-color: transparent;
    border: none;
    color: ${colors.brandOne};
    cursor: pointer;
    display: flex;
    justify-content: center;
    justify-self: center;
    padding: 0;
    position: relative;

    svg {
      height: 48px;
      width: 48px;
    }
  `};
`;

const StyledSearchContainer = styled.div`
  align-items: baseline;
  display: flex;
  flex-direction: column;
  gap: ${({ theme: { space } }) => space.xxxSmall};
  height: 100%;
  position: relative;
  width: 100%;

  pointer-events: none;

  > * {
    pointer-events: all;
  }
`;

const StyledOverlay = styled.button`
  ${({ theme: { colors } }) => css`
    background-color: ${colors.brandOne};
    border: none;
    inset: 0 0 0 0;
    opacity: 0.9;
    position: absolute;
  `};
`;

const StyledModal = styled.aside`
  ${({ theme: { layers, media, space } }) => css`
    display: grid;
    grid-template-columns: minmax(250px, 720px) auto;
    height: 100dvh;
    inset: 0 0 0 0;
    padding-block: ${space.fluidSmallToXxxLarge};
    padding-inline: ${space.xxSmall};
    position: fixed;
    width: 100dvw;
    z-index: ${layers.modalOverlay};

    ${StyledSearchContainer} {
      grid-column: 1;
      max-height: min(750px, 100dvh - ${space.fluidSmallToXxxLarge} * 2);
    }

    ${StyledCloseButton} {
      grid-column: 2;
    }

    @media (min-width: ${media.xSmall}) {
      gap: ${space.fluidXSmallToMedium};
      padding-inline: ${space.fluidSmallToXxxxLarge};
    }

    @media (min-width: ${media.medium}) {
      grid-template-columns: auto minmax(250px, 720px) auto;

      ${StyledSearchContainer} {
        grid-column: 2;
      }

      ${StyledCloseButton} {
        grid-column: 3;
      }
    }
  `};
`;

const SearchModal: FC<SearchModalInterface> = () => {
  const [showSearch, setShowSearch] = useState<boolean>(false);
  const [showResults, setShowResults] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const searchRef = useRef<HTMLInputElement>(null);

  const location = useLocation();

  const handleOpenClose = () => {
    setShowSearch(!showSearch);
    setSearchQuery('');
    setShowResults(false);
  };

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setSearchQuery(event.target.value);

    if (event.target.value.length > 2) {
      setShowResults(true);
    } else {
      setShowResults(false);
    }
  };

  const handleClearInput = () => {
    setSearchQuery('');
    setShowResults(false);
  };

  const handleOnSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const queryReplace = searchQuery.replace(/ /g, '+');
    navigate(`/search?q=${queryReplace}`);
    setShowSearch(false);
    setShowResults(false);
  };

  useEffect(() => {
    const { body } = document;
    const scrollbarWidth = window.innerWidth - body.clientWidth;

    if (showSearch && searchRef.current) {
      searchRef.current?.focus();
    }

    if (showSearch) {
      body.style.overflow = 'hidden';
      body.style.paddingRight = `${scrollbarWidth}px`;
    } else {
      body.style.overflow = '';
      body.style.paddingRight = '';
    }
  }, [showSearch]);

  useEffect(() => {
    setShowSearch(false);
  }, [location]);

  return (
    <>
      <Button
        a11yTitle="show search"
        iconOnly
        notFullWidth
        onClick={handleOpenClose}
        small
        variant="secondaryOutline"
      >
        <SearchIcon />
      </Button>
      <Portal id="search-portal">
        {showSearch && (
          <StyledModal>
            <StyledOverlay
              aria-label="Close search overlay"
              onClick={handleOpenClose}
              tabIndex={-1}
              id="close-search-overlay"
            />

            <StyledSearchContainer>
              <SearchFormMemo
                autoFocus
                inputValue={searchQuery}
                onChange={handleOnChange}
                onClick={handleClearInput}
                onSubmit={handleOnSubmit}
                ref={searchRef}
              />

              {showResults && (
                <StyledSearchResults>
                  <div>
                    <SearchResults searchQuery={searchQuery} />
                  </div>
                </StyledSearchResults>
              )}
            </StyledSearchContainer>

            <StyledCloseButton
              aria-label="Close search overlay"
              onClick={handleOpenClose}
              type="button"
            >
              <Close color="utilityWhite" />
            </StyledCloseButton>
          </StyledModal>
        )}
      </Portal>
    </>
  );
};

export { SearchModal };
